import { useState, useLayoutEffect } from "react";
import { graphql } from "gatsby";
import styled, { keyframes } from "styled-components";

// Utils
import { Color, responsive, rem } from "../utils/style";
import { Icons } from "../utils/react-svg";

// Components
import Container from "../components/Container";
import Row from "../components/Row";

import Text from "../components/Text";
import PageSEO from "../components/seo/Page";
import ProductCategoryCards from "../components/product/ProductCategoryCards";
import JournalBreadcrumbs from "../components/journal/JournalBreadcrumbs";
import FeaturedArticleCard from "../components/hub/FeaturedArticleCard";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  margin-top: 52px;
  padding: 24px 0 0;

  ${responsive.md`
    margin-top: 64px;
    padding: 56px 0 0;
  `}
`;

const SubcategoryHeader = styled.div`
  border-bottom: 1px solid ${Color.superFadedBlue};
  padding-bottom: 24px;
  margin-bottom: 20px;

  ${responsive.md`
    padding-bottom: 56px;
    margin-bottom: 60px;
  `};

  span {
    color: ${Color.lessFadedBlue};
  }
`;

const SubcategoryTitle = styled.h1`
  position: relative;
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  letter-spacing: -0.3px;
  font-weight: 500;
  margin: 0;
  padding-top: 20px;

  &::before {
    content: "";
    display: block;
    width: 32px;
    height: 4px;
    background-color: ${Color.ritualBlue};

    position: absolute;
    top: 0;
    left: 0;
  }

  ${responsive.md`
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: -0.5px;
    padding-top: 28px;
  `};
`;

const ArticlesContainer = styled.div`
  position: relative;
  width: 100%;

  & > div {
    width: calc(50% - (15px / 2));
    margin-right: 15px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    ${responsive.sm`
      width: calc(33.333% - (40px / 3));
      margin-right: 20px;

      &:nth-child(2n) {
        margin-right: 20px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    `};

    & > a > div[class*="FeaturedArticleCard__Article"] {
      width: 100% !important;
      margin-left: 0;
      margin-right: 0;

      h2 {
        font-size: ${rem(16)};
        line-height: ${rem(26)};

        ${responsive.md`
          font-size: ${rem(18)};
          line-height: ${rem(28)};
        `};
      }
    }
  }
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const SeeMoreButton = styled.button`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  padding: 16px 0 0;
  margin: 20px 0 0;
  background: none;

  ${responsive.md`
    margin-top: 60px;
  `};

  border-top: 1px solid ${Color.superFadedBlue};
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(24)};

  svg {
    width: 14px;
    height: 8px;
    margin-left: 8px;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      animation: ${bounce} 0.5s ease-in-out;
    }
  }
`;

const ArticleSubcategory = (props) => {
  const [displayAll, setDisplayAll] = useState(false);

  useLayoutEffect(() => {
    props.updatePageData({
      label: "Article Subcategory",
    });
  });

  const {
    data: {
      contentfulFeaturedSubcategory: { category, key, title },
      allContentfulArticle: { nodes: articles },
    },
  } = props;

  const selectedCategory = category.find(
    (c) => c.slug === props.pageContext.category,
  );

  const displayArticles = articles.slice(
    0,
    displayAll ? articles.length : Math.min(12, articles.length),
  );

  const showSeeMoreButton = articles.length > displayArticles.length;

  return (
    <>
      <PageSEO />
      <Wrapper>
        <Container>
          <Row>
            <div className="col-12 p-sm-0 mb-5 mb-md-7">
              <JournalBreadcrumbs
                category={selectedCategory}
                subcategory={{
                  key,
                  title,
                }}
              />
            </div>
            <div className="col-12 p-sm-0">
              <SubcategoryHeader className="d-flex flex-row justify-content-between align-items-end">
                <SubcategoryTitle>{title}</SubcategoryTitle>
                <span>
                  <Text
                    id="journal.articles-list"
                    defaultMessage="{quantity} Articles"
                    values={{
                      quantity: articles.length,
                    }}
                  />
                </span>
              </SubcategoryHeader>
            </div>
            <div className="col-12 p-sm-0 position-relative">
              <ArticlesContainer>
                {displayArticles.map((article, i) => (
                  <FeaturedArticleCard
                    key={i}
                    article={article}
                    category={selectedCategory}
                  />
                ))}
              </ArticlesContainer>
              {showSeeMoreButton && (
                <SeeMoreButton onClick={() => setDisplayAll(!displayAll)}>
                  <Text id="journal.see-more" defaultMessage="See More" />{" "}
                  <Icons.CaretDown />
                </SeeMoreButton>
              )}
            </div>
          </Row>
        </Container>
        <ProductCategoryCards className={"mt-7 mt-md-9 mb-7 mb-md-9"} />
      </Wrapper>
    </>
  );
};

// TODO: Clean up featuredArticle query into fragment, also used in articles template
export const query = graphql`
  query ContentfulArticleSubcategoryQuery(
    $locale: String!
    $category: String!
    $subcategory: String!
  ) {
    contentfulFeaturedSubcategory(
      node_locale: { eq: $locale }
      key: { eq: $subcategory }
    ) {
      title
      key
      category {
        slug
        title
      }
    }
    allContentfulArticle(
      filter: {
        category: { slug: { eq: $category } }
        subcategory: { key: { eq: $subcategory } }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        ...expandPrimaryContent
        ...featuredArticlesFragment
        heroImage {
          file {
            url
          }
          desktop: gatsbyImageData(
            layout: CONSTRAINED
            width: 960
            height: 560
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
          mobile: gatsbyImageData(
            layout: CONSTRAINED
            width: 960
            height: 560
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
        }
      }
    }
  }
`;

export default ArticleSubcategory;
