import React, { FunctionComponent } from "react";
import styled from "styled-components";

// Utils
import { Color, Opacity } from "../../utils/style";

// Components
import MagicLink from "../MagicLink";

// Styled Elements
const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  a {
    position: relative;
    margin: 0 12px 0 0;
    padding: 0 12px 0 0;

    &:hover {
      opacity: ${Opacity.light};
    }

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${Color.fadedBlue};

      position: absolute;
      top: calc(50% - 2px);
      right: -2px;
    }

    &:last-child {
      margin: 0;
      padding: 0;

      &::after {
        display: none;
      }
    }
  }
`;

// Interfaces
interface ICategory {
  slug: string;
  title: string;
}

interface ISubcategory {
  key: string;
  title: string;
}

// Component
const JournalBreadcrumbs: FunctionComponent<{
  category?: ICategory;
  subcategory?: ISubcategory;
}> = ({ category, subcategory }) => {
  const baseUrl = "/articles";

  return (
    <>
      <Breadcrumbs>
        <MagicLink to={baseUrl}>The Journal</MagicLink>
        {category && (
          <MagicLink to={`${baseUrl}/categories/${category.slug}`}>
            {category.title}
          </MagicLink>
        )}
        {category && subcategory && (
          <MagicLink
            to={`${baseUrl}/categories/${category.slug}/${subcategory.key}`}
          >
            {subcategory.title}
          </MagicLink>
        )}
      </Breadcrumbs>
    </>
  );
};

export default JournalBreadcrumbs;
